$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.filterToggleButton {
  @extend %button-reset;
  padding: 16px 20px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: $background-default;
  border-radius: $radius-1;
  border: $border-1 solid $border-default;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    width: unset;
  }

  .filterToggleButtonTitle {
    color: $lights-high;
    white-space: nowrap;
    margin-right: 80px;

    @include text-body2Medium;
  }

  .filterToggleButtonIcon {
    min-width: 18px;
    min-height: 18px;
    color: $lights-high;
  }
}

.filtersModal {
  &[class*="modal"] {
    background-color: $background-dim;
    margin-top: $header_height;

    @media screen and (min-width: $breakpoint_l) {
      margin-top: 0;
    }

    &[class*="size-medium"] {
      padding: 0;
      max-width: 100vw;
      min-height: max-content;
      vertical-align: top;
      border-radius: 0;
    }
  }
}
